import React from 'react';
import { TypoXLBold } from '@atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import Image from 'next/image';
import styles from './css';

const AboutHafhSection = (): JSX.Element => (
  <section className="about-hafh">
    <div className="section-inner">
      <div className="title">
        <TypoXLBold text="HafHとは？" />
        <div className="divider" />
      </div>
      <Desktop>
        <Image
          alt="HafHとは？"
          height={450}
          src="/images/lp/top/how-to-use-pc.png?v=6"
          style={{
            height: 'auto',
            width: '100%',
          }}
          width={960}
        />
      </Desktop>
      <Mobile>
        <Image
          alt="HafHとは？"
          height={1752}
          src="/images/lp/top/how-to-use-mobile.png?v=6"
          style={{
            height: 'auto',
            width: '100%',
          }}
          width={614}
        />
      </Mobile>
    </div>
    <style jsx={true}>{styles}</style>
  </section>
);

export default AboutHafhSection;
