import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .invite-description-item {
    text-align: center;
    width: 100%;
    white-space: pre-wrap;
    .label {
      display: inline-block;
      color: ${COLORS.white};
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      padding: 16px;
      gap: 10px;
      border-radius: 12px 12px 0px 0px;

      &.gray {
        background: ${COLORS.black700};
      }
      &.blue {
        background: ${COLORS.primary};
      }
      &.red {
        background: ${COLORS.red500};
      }
      &.blue400 {
        background: ${COLORS.blue400};
      }
      &.purple400 {
        background: ${COLORS.purple400};
      }
      &.purple800 {
        background: ${COLORS.purple800};
      }
    }
    .invite-description-item-inner {
      border-radius: 0px 0px 8px 8px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      &.gray {
        background: ${COLORS.black100};
      }
      &.blue {
        background: ${COLORS.purple100};
        border: 1px solid ${COLORS.primary};
      }
      &.red {
        background: ${COLORS.red100};
        border: 1px solid ${COLORS.red500};
      }
      &.blue400 {
        background: ${COLORS.lightGray};
      }
      &.purple400 {
        background: ${COLORS.lightGray};
      }
      &.white {
        background: ${COLORS.white};
      }
      :global(.desc-detail) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        :global(.given-coin) {
          display: flex;
          align-items: baseline;
          justify-content: center;
          :global(.typo-xxxxl) {
            line-height: normal;
          }
          :global(.typo-xxs) {
            text-align: left;
            margin-left: 4px;
            line-height: 14px;
          }
        }
        :global(.after-second-coin) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export default styles;
