import { useAppStore } from '@hooks/useAppStore';

/**
 * After September 13th We will remove this condition 'cause JP it will have one campaign regularly.
 */
export const useIsJPStdReferralCampaign = () => {
  const store = useAppStore();
  const { referralCampaign } = store.getState();

  return referralCampaign?.code === '20240821-jp-std';
};

export const useReferralCampaignCode = () => {
  const store = useAppStore();
  const { referralCampaign } = store.getState();

  return referralCampaign?.code;
};
