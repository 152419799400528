import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .convert-section {
    background-image: url('/images/lp/cv-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    place-items: center;

    .convert-section-body {
      position: relative;
      padding: 120px 0 60px;
    }

    .neighbor-plans-wrapper {
      margin: 16px;
    }

    .convert-heading {
      text-align: center;
    }
  }
  .call-to-action-button {
    margin: auto;
    width: fit-content;
    position: inherit;
  }

  .signup-campaign-cta-cont {
    background-color: ${COLORS.purple100};
    padding: 56px 16px;
    text-align: center;
    width: max-content;
    width: 100%;
    .signup-campaign-cta-cont-title-top {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        // Only SP should be break line
        br {
          display: none;
        }
      }
    }
    .signup-campaign-period {
      background-color: ${COLORS.white};
      padding: 8px 36px;
      border-radius: 18px;
      margin: 16px auto 0;
      width: max-content;
    }
    .signup-campaign-cta-inner {
      max-width: 448px;
      margin: 24px auto 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        max-width: 928px;
        flex-direction: row;
        justify-content: center;
      }
    }
    .signup-campaign-notice {
      text-align: left;
      padding: 0 24px;
      margin: 24px auto;
      max-width: max-content;
    }
    .signup-campaign-button {
      margin: 24px auto 0;
      max-width: 448px;
      :global(.btn) {
        height: 60px;
        border-radius: 30px;
      }

      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        max-width: 232px;
      }
    }
  }
`;

export default styles;
