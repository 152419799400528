import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './css';

type Props = {
  backgroundColor?: string;
  children: ReactNode;
  color: string;
  label: string;
};

const InviteDescriptionItem = ({
  backgroundColor,
  children,
  color,
  label,
}: Props) => (
  <div className="invite-description-item">
    <div className={classNames('label', color)}>{label}</div>
    <div
      className={classNames(
        'invite-description-item-inner',
        backgroundColor || color
      )}
    >
      {children}
    </div>
    <style jsx={true}>{styles}</style>
  </div>
);

export default InviteDescriptionItem;
